import { PaymentTerm, PaymentTermLabel } from "../definition"

export const CONFIG_PREFFER_PAYMENTTERM = {
  [PaymentTerm.COD]: {
    show: true,
    label: PaymentTermLabel.COD
  },
  [PaymentTerm.CREDIT]: {
    show: true,
    label: PaymentTermLabel.CREDIT
  },
  [PaymentTerm.PPP]: {
    show: false,
    label: PaymentTermLabel.PPP
  },
  [PaymentTerm.FPP]: {
    show: false,
    label: PaymentTermLabel.FPP
  },
  [PaymentTerm.OTHER]: {
    show: false,
    label: PaymentTermLabel.OTHER
  },
}

export const CONFIG_BOOKING_PAYMENTTERM = {
  [PaymentTerm.COD]: {
    show: true,
    label: PaymentTermLabel.COD
  },
  [PaymentTerm.CREDIT]: {
    show: true,
    label: PaymentTermLabel.CREDIT
  },
  [PaymentTerm.PPP]: {
    show: true,
    label: PaymentTermLabel.PPP
  },
  [PaymentTerm.FPP]: {
    show: true,
    label: PaymentTermLabel.FPP
  },
  [PaymentTerm.OTHER]: {
    show: true,
    label: PaymentTermLabel.OTHER
  },
}

export const DEFAULT_PAYMENT_TERM = PaymentTerm.FPP
